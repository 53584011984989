// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Print
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-no-qualifying-type, selector-max-type */

/* These styles are intended to be global */

@media print {
    // Vendor

    @import "../../vendor/_gutenberg";

    // Custom

    a[href^="//"]::after {
        content: " (" attr(href) ")";
        font-size: 80%;
    }
}
